import React from 'react';
import { SvgIconProps } from '../icons.types';

const SvgSun = ({
    size,
    title = 'Sun',
    ...props
}: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="sun_svg__feather sun_svg__feather-sun"
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <circle cx={12} cy={12} r={5} />
        <path d="M12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42" />
    </svg>
);

export default SvgSun;
